import React, { useRef, useState } from "react"
import { useFirebase } from '../firebase'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: '0 1px 4px rgba(0,0,0,.04)',
        border: '1px solid rgba(0,0,0,.09)',
        borderRadius: '3px',
        padding: '15px 20px',        
        marginBottom: '15px',
        backgroundColor: '#c11f1f08'
    },
    innerBox: {
        maxHeight: '0px',
        marginBottom: '0px',
        transition: 'all 0.4s linear',
        overflowY: 'hidden',
    },
    fullWidth: {
        width: '100%',
        //maxWidth: '450px',
        marginBottom: '15px',
        backgroundColor: '#fff'
    },
    extended: {
        maxHeight: '500px',
        paddingBottom: '15px'
    },
    button: {
        marginRight: '15px',
    }
}));

const AddComment = ({identifier}) => {
    const firebaseContext = useFirebase();
    const classes = useStyles();
    const [focused, setFocused] = useState(false);
    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [nameError, setNameError] = useState(false);
    const [contentError, setContentError] = useState(false);
    const textInput = useRef(null);
    const [done, setDone] = useState(false);

    const postComment = () => {
        setNameError(name.length < 2);
        setContentError(content.length < 5);

        if(name.length < 2 || content.length < 5)
            return;
        
        if(firebaseContext !== undefined)
        {
            firebaseContext.newComment(identifier,name,content).then(() => {
                console.debug("data inserted");
                setDone(true)
            })
            .catch(() => {
                console.debug("error occured by insert new comment");
            })
        }
    }

    if(done)
        return (<div className={classes.box}>
            <div className={clsx(classes.extended)}>
                <b>Dein Kommentar wurde gespeichert. Danke!</b>
            </div>
            
        </div>);
    else
        return (
            <div className={classes.box}>
                <div className={clsx(classes.extended)}>
                    Schreibe einen Kommentar<br/>
                </div>
                <div className={clsx(classes.extended)}>
                    <TextField error={nameError} helperText={nameError?"Dein Name muss mindestens 2 Zeichen lang sein":""} inputRef={textInput} variant="outlined" className={classes.fullWidth} label="Name" onChange={(e) => setName(e.target.value)} /><br/>
                    <TextField error={contentError} helperText={contentError?"Dein Kommentar muss mindestens 5 Zeichen lang sein":""} variant="outlined" className={classes.fullWidth} multiline={true} rows={3} label="Nachricht" onChange={(e) => setContent(e.target.value)} /><br/>
                    <button className={classes.button} onClick={() => postComment()}>Abschicken</button>
                </div>
            </div>
        )
}

/*
    <div onClick={() => {setFocused(true); textInput.current.focus();}} className={clsx([(!focused)?classes.extended:classes.extended, classes.innerBox])}>
        Schreibe einen Kommentar
    </div>

<button className={classes.button} onClick={() => setFocused(false)}>Abbrechen</button>
*/

export default AddComment