import React from "react"
import CommentList from './commentlist'
import AddComment from './addcomment'

const CommentComponent = ({identifier}) => {
    return (
        <>
            <AddComment identifier={identifier} />
            <CommentList identifier={identifier} />
        </>
    )
}

export default CommentComponent