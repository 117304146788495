import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import {Grid} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

//import Bio from "../components/bio"
import CommentComponent from "../components/comment"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import AdSense from 'react-adsense';

const useStyles = makeStyles({
  captionBox: {
    borderTop: '5px solid #c11f1f',
    borderLeft: '15px solid #c11f1f',
    padding: '0 0 0 15px',
    margin: '0 0 20px 0',
    color: '#848484',
    fontWeight: '100',
    letterSpacing: '-1px',
  },
  commentsection: {
    padding: 0,
    margin: 0,
    marginLeft: '1.61rem',
    marginBottom: '1.21rem',
    textTransform: 'none'
  },
  sidebar: {
    maxWidth: '260px',
    textTransform: 'lowercase',
    borderTop: '5px solid #d0d0d0#',
    
    fontWeight: '200',
    fontSize: '15px',
    paddingLeft: '7px',

    '& p': {
      padding: 0,
      margin: 0,
      marginLeft: '1.61rem',
      marginBottom: '1.21rem',
      textTransform: 'none'
    },

    '& h2': {
      borderTop: '5px solid #c11f1f',
      borderLeft: '15px solid #c11f1f',
      padding: '0 0 0 15px',
      margin: '0 0 20px 0',
      color: '#848484',
      fontWeight: '100',
      letterSpacing: '-1px',
    },

    '& ul': {
      listStyleType: 'none',
      '& li': {
        margin: 0,
        fontSize: '15px',
        padding:'0 0 5px 0',
        '&::before': {
          content: '"\\00BB \\0020"',
          marginLeft: '-15px',
          },
      },
    }
  },
  blogContent: {
    ...scale(-1 / 5),
    '& h1': {...scale(1)},
    '& h2': {...scale(0.4)},
    '& h3': {...scale(0), },
    '& a': {
      textDecoration: 'underline',
    },
    '& table': {
      tableLayout: 'fixed!important',
    },
    '& li': {
      marginBottom: 0,
      '& p': {
        marginBottom: 0,
      },
      '& ol, ul': {
        marginTop: 0,
        marginBottom: 0,
      }
    },
    '& .gatsby-resp-image-wrapper': {
      marginLeft: '0!important',
      marginRight: '0!important',
    },
    '& blockquote': {
      background: '#f9f9f9',
      borderLeft: '10px solid #ccc',
      margin: '1.5em 10px',
      padding: '0.5em 10px',
      quotes: '"“" "‘"'
    },
    '& blockquote:before': {
      color: '#ccc',
      content: 'open-quote',
      fontSize: '4em',
      lineHeight: '0.1em',
      marginRight: '0.25em',
      verticalAlign: '-0.4em'
    },
    '& blockquote p': {
      display: 'inline'
    }
  },
});

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const classes = useStyles();
  const slug = data.markdownRemark.fields.slug
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { relatedPosts } = pageContext
  const author = post.frontmatter.authorLink

  let imgUrl = null;
  if(post.frontmatter.coverImage)
    imgUrl = data.site.siteMetadata.siteUrl + post.frontmatter.coverImage.publicURL;

  let imgUrlLD = null;
  if(post.frontmatter.coverImage)
  {
    imgUrlLD = {
    "image": {
      "@type": "ImageObject",
      "url": imgUrl,
      "height": post.frontmatter.coverImage.childImageSharp.fixed.height,
      "width": post.frontmatter.coverImage.childImageSharp.fixed.width,
      },
    }
  }
  
  let schemaLD = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://google.com/article"
    },
    "headline": post.frontmatter.title,
    "description": post.frontmatter.description,
    "datePublished": post.frontmatter.date,
    "dateModified": post.frontmatter.date,
    "author": {
      "@type": "Person",
      "name": author.frontmatter.name
    },
     "publisher": {
      "@type": "Organization",
      "name": "Redlabs",
    },
  }

  schemaLD = Object.assign(schemaLD, imgUrlLD)

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        article={true}
        image={imgUrl}
        schemaLD={schemaLD}
      />
      <Grid container alignItems="baseline" spacing={3}>
        <Grid item style={{width: '100%', maxWidth: '820px'}}>
        <article>
        <header>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: 0,
            }}
          >
            {post.frontmatter.date}
          </p>
          <h1
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.description}
          </p>
          {post.frontmatter.coverImage && (
            <Image alt={post.frontmatter.title} fluid={post.frontmatter.coverImage.childImageSharp.fluid} sizes={{ ...post.frontmatter.coverImage.childImageSharp.fluid, aspectRatio: 16/9 }} 
            style={{padding: '0px', margin: '45px 0'}}/>
          )}
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} className={classes.blogContent} />
        <p>
          <AdSense.Google
            client='ca-pub-8461059408203244'
            slot='6090880065'
            format='auto'
            responsive='true'
          />
        </p>
      </article>
        </Grid>
        <Grid item style={{/*position: 'sticky', top:0*/}}>
          {author && (
            <div className={classes.sidebar}>
              <h2>geschrieben von</h2>
              {author.frontmatter.profilimage && (
              <Image alt={author.frontmatter.name} fluid={author.frontmatter.profilimage.childImageSharp.fluid} sizes={{ ...author.frontmatter.profilimage.childImageSharp.fluid, aspectRatio: 16/9 }} 
              style={{padding: '0px', margin: '25px auto', width:'80px', height: '80px', borderRadius: '50%'}}/>
              )}
              <p>{author.rawMarkdownBody}</p>
              <p><i>{author.frontmatter.name}</i></p>
            </div>
          )}
          <div className={classes.sidebar}>
            <h2>mehr artikel</h2>
            <ul>
              {relatedPosts.map((item,index) => (
                <li key={`rp_${index}`}>
                  <Link to={item.node.fields.slug} style={{textTransform:"capitalize"}}>
                    {item.node.frontmatter.title}
                    <img src={item.node.frontmatter.coverImage.publicURL} alt={item.node.frontmatter.title} />  
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.sidebar}>
            <h2>werbung</h2>
            <p>
            <AdSense.Google
              client='ca-pub-8461059408203244'
              slot='2957272655'
              format='auto'
              responsive='true'
            />
            </p>
          </div>
        </Grid>
      </Grid>

      <div id="comment-section" className={classes.sidebar} style={{maxWidth: '820px'}}>
        <h2>kommentare</h2>
        <div className={classes.commentsection}>
          <CommentComponent identifier={slug} />
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 120)
      html
      tableOfContents
      fields {
        slug
      }
      frontmatter {
        authorLink {
          frontmatter {
            email
            name
            profilimage {
              publicURL
              childImageSharp{
                fluid (maxWidth:500){
                  src,
                  srcSet
                  aspectRatio
                  sizes
                  base64
                }
              }
            }
          }
          html
          rawMarkdownBody
        }
        title
        date(formatString: "D.M.YYYY")
        description
        coverImage{ 
          publicURL
          childImageSharp{
            fluid (maxWidth:500, quality: 98){
              src,
              srcSet
              aspectRatio
              sizes
              base64
            }
            fixed {
              height
              width
            }
          }
        }
      }
    }
  }
`



/*
<nav>
<ul
  style={{
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    listStyle: `none`,
    padding: 0,
  }}
>
  <li>
    {previous && (
      <Link to={previous.fields.slug} rel="prev">
        ← {previous.frontmatter.title}
      </Link>
    )}
  </li>
  <li>
    {next && (
      <Link to={next.fields.slug} rel="next">
        {next.frontmatter.title} →
      </Link>
    )}
  </li>
</ul>
</nav>


*/