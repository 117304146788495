import React, { useEffect, useState } from "react"
import {useFirebase} from '../firebase'
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: '0 1px 4px rgba(0,0,0,.04)',
        border: '1px solid rgba(0,0,0,.09)',
        borderRadius: '3px',
        padding: '15px 20px',
        marginBottom: '15px',
        '& button':{
            marginTop: '15px',
        }
    },
    headerInfo: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
        '& div:first-child': {
            marginRight: '15px',
        }
    },
    smallText: {
        fontSize: '13px',
    }
}));

const getDateString = (inputDate) => {
    return inputDate.getDate()+"."+(inputDate.getMonth()+1)+"."+inputDate.getFullYear();
}

const CommentEntry = (props) => {
    const classes = useStyles();
    const {author, content, created} = props.data;
    return (
        <div className={classes.box}>
            <div className={classes.headerInfo}>
                <div>
                    <AccountCircleIcon style={{ fontSize: 40 }} />
                </div>
                <div className={classes.smallText}>
                    <div>{author} hat am {getDateString(created.toDate())} geschrieben:</div>
                </div>
            </div>
            <div>
                {content}
            </div>
        </div>
    )
}   

const CommentList = ({identifier}) => {
    const firebaseContext = useFirebase();
    const [loading, setLoading] = useState(true);
    const [itemList, setItemList] = useState([]);
    
    useEffect(() => {
        if(firebaseContext !== undefined)
        {
            firebaseContext
            .comments(identifier)
            .get()
            .then((snapshot) => {
                if (snapshot.empty) {
                    console.debug(`snapshot is empty`);
                    setItemList([]);
                    setLoading(false);
                    return;
                }
                const commentData = snapshot.docs.map(doc => {
                    console.log(doc.data().created.toDate().getTime());
                    return {
                      ...doc.data(),
                      id: doc.id
                    };
                  });
                  console.log(commentData)
                setItemList(commentData); setLoading(false);})
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
        }
    }, [firebaseContext, identifier]);

    

    if(loading)
        return 'Kommentare laden ...';
    else
        return itemList.map((item, idx) => (
            <CommentEntry key={idx} data={item} />
        ))
}

export default CommentList